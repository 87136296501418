import styled from 'styled-components';
import { TableContainer, TableCell, Table } from '@mui/material/';

interface IDivProps {
  colorIndex?: number | boolean;
}

export const ColoredLegend = styled.div<IDivProps>`
  width: 10px;
  z-index: 100;
  height: 36px;
  margin: -8px 4px -8px -20px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  background-color: ${(props) =>
    typeof props.colorIndex === 'number'
      ? props.theme.chartColors[props.colorIndex % props.theme.chartColors.length]
      : props.theme.defaultLegendColor};
`;

export const FlexDiv = styled.div<IDivProps>`
  display: flex;
  align-items: center;
`;

interface ICellProps {
  $freeze?: boolean;
  $paddingLeft?: boolean;
  $borderRight?: boolean;
  $zIndex?: number;
  $leftOnFreeze?: number | false;
}

export const StyledCell = styled(TableCell)<ICellProps>`
  @media ${(props) => props.theme.device.mobileXL} {
    padding-left: ${(props) => (props.$paddingLeft ? '20px' : '')};
    position: ${(props) => (props.$freeze ? 'sticky' : '')};
    z-index: ${(props) => (props.$zIndex ? `${props.$zIndex}` : '1')};
    left: ${(props) =>
      props.$freeze ? (props.$leftOnFreeze ? `${props.$leftOnFreeze}px` : '0') : ''};
    border-right: ${(props) => (props.$borderRight ? `1px solid ${props.theme.lineColor}` : '')};
  }
`;

interface ISpanProps {
  $width?: string;
  $noBreak?: boolean;
}

export const SpanWidth = styled.span<ISpanProps>`
  text-overflow: ellipsis;
  overflow: hidden;
  width: ${(props) => props.$width ?? ''};
  @media ${(props) => props.theme.device.mobileXL} {
    /* width: ${(props) => (props.$width ? '200px' : '')}; */
    width: 30vw;
    white-space: ${(props) => (props.$noBreak ? 'normal' : 'nowrap')};
  }
`;

interface IContainerProps {
  $noScroll?: boolean;
}
export const StyledTableContainer = styled(TableContainer)<IContainerProps>`
  width: 100%;
  max-height: ${(props) => (props.$noScroll ? '' : '70vh')};

  overflow-x: auto;
  @media ${(props) => props.theme.device.tablet} {
    width: 97.5vw;
  }
`;

interface ITableProps {
  $paddingLeft?: boolean;
}
export const StyledTable = styled(Table)<ITableProps>`
  border-spacing: 0 5px;
  border-collapse: separate;
  padding-left: ${(props) => (props.$paddingLeft ? '12px' : '')};
  @media ${(props) => props.theme.device.tablet} {
    padding-left: 0;
  }
`;
