import styled from 'styled-components';
import { ReactComponent as Exchange } from '../../assets/exchange.svg';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Carteira = styled.div`
  display: flex;
  @media ${(props) => props.theme.device.tablet} {
    margin-top: 8px;
  }
`;

export const StyledExchange = styled(Exchange)`
  margin-right: 12px;
  fill: ${(props) => props.theme.titleFontColor};
  @media ${(props) => props.theme.device.tablet} {
    margin-right: 0;
  }
`;

export const TrocarCarteiraText = styled.span`
  @media ${(props) => props.theme.device.tablet} {
    display: none;
  }
`;

export const TrocarCarteiraContainer = styled.div`
  display: flex;
  margin-left: 28px;
  align-items: center;
  color: ${({ theme }) => theme.titleFontColor};
  margin-bottom: 4px;
  position: relative;
  cursor: pointer;
  @media ${(props) => props.theme.device.tablet} {
    margin-left: 16px;
    margin-right: 8px;
  }
`;
