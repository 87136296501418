import { Skeleton } from '@mui/material';
import styled from 'styled-components';

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: auto;
  @media ${(props) => props.theme.device.mobileXL} {
    width: 97.5vw;
  }
`;
export const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-color: ${({ theme }) => theme.lineColor};
  min-width: 188px;
  border-width: 1.5px;
  border-style: solid;
  border-radius: 8px;
  margin: 12.5px;
  text-align: center;
  justify-content: center;
  height: 80px;
  background-color: ${({ theme }) => theme.backgroundColorSecondary};
`;

export const CardTitle = styled.div`
  margin-bottom: 12px;
  font-size: ${({ theme }) => theme.mdFontSize};
  color: ${({ theme }) => theme.tableCellColor};
`;

export const CardContent = styled.div`
  font-weight: bold;
  font-size: ${({ theme }) => theme.lgFontSize};
  color: ${({ theme }) => theme.tableCellDarkerColor};
`;

export const StyledSkeleton = styled(Skeleton)`
  border-color: ${({ theme }) => theme.lineColor};
  min-width: 188px;
  border-width: 1.5px;
  border-style: solid;
  border-radius: 8px;
  margin: -12.5px 12.5px 0 12.5px;
  height: 140px;
`;

export const CardsWrapperPDF = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CardPDF = styled.div`
  display: flex;
  flex-direction: column;
  border-color: ${({ theme }) => theme.lineColor};
  min-width: 13vw;
  border-width: 1.5px;
  border-style: solid;
  border-radius: 0.5vw;
  margin: 0.8vw;
  text-align: center;
  justify-content: center;
  height: 6vw;
  background-color: ${({ theme }) => theme.backgroundColorSecondary};
`;

export const CardTitlePDF = styled.div`
  margin-bottom: 1vw;
  font-size: 1.1vw;
  color: ${({ theme }) => theme.tableCellColor};
`;

export const CardContentPDF = styled.div`
  font-weight: bold;
  font-size: 1.3vw;
  color: ${({ theme }) => theme.tableCellDarkerColor};
`;
