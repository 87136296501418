import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import preferenceReducer from './reducers/preferenceSlice';
import resumoCartReducer from './reducers/resumoCartSlice';
import perfHistReducer from './reducers/perfHistSlice';
import posConReducer from './reducers/posConSlice';
import cartExpReducer from './reducers/cartExpSlice';
import fluxoAtivosReducer from './reducers/fluxoAtivosSlice';
import fluxoCaixaReducer from './reducers/fluxoCaixaSlice';
import estatsReducer from './reducers/estatsSlice';
import loadMinimizedReducer from './reducers/loadMinimizedSlice';
import { listenerMiddleware } from './listenerMiddleware';

export const store = configureStore({
  reducer: {
    preference: preferenceReducer,
    resumoCart: resumoCartReducer,
    perfHist: perfHistReducer,
    posCon: posConReducer,
    cartExp: cartExpReducer,
    fluxoAtivos: fluxoAtivosReducer,
    fluxoCaixa: fluxoCaixaReducer,
    estats: estatsReducer,
    loadMinimized: loadMinimizedReducer,
  },
  middleware: (getDefaultMiddleware) => {
    const defaultMiddleware = getDefaultMiddleware();
    defaultMiddleware.unshift(logger);
    defaultMiddleware.unshift(listenerMiddleware.middleware);
    return defaultMiddleware;
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
