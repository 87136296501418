import React, { useEffect, useState, PropsWithChildren } from 'react';
import { Modal } from '@mui/material';
import { StyledModalContainer } from './style';
import { useAppSelector } from '../../services/reduxHooks';

interface Props {
  onCloseHandle: ((value: any) => void | null) | ((data: any) => Promise<void>);
  open: boolean;
  minWidth?: number;
  maxWidth?: number;
  width?: string;
}

export const CustomModal = ({
  open,
  onCloseHandle,
  minWidth,
  maxWidth,
  width,
  children,
}: PropsWithChildren<Props>) => {
  const [modalOpen, setModalOpen] = useState(false);
  const flagTrocaSenhaStore = localStorage.getItem('flagTrocaSenha');
  const [flagTrocaSenha] = useAppSelector((state) => [
    flagTrocaSenhaStore || state.preference.flagTrocaSenha,
  ]);

  useEffect(() => {
    if (typeof flagTrocaSenha === 'string') {
      localStorage.setItem('flagTrocaSenha', flagTrocaSenha)
    }
  }, [flagTrocaSenha])

  const handleClose = (event: any, reason: string) => {
    if (flagTrocaSenha === '1') {
      if (reason !== 'backdropClick') {
        setModalOpen(false);
        onCloseHandle(null);
      }
    } else {
      setModalOpen(false);
      onCloseHandle(null);
    }
  }

  useEffect(() => {
    setModalOpen(open);
  }, [open]);

  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description">
      <StyledModalContainer width={width} minWidth={minWidth} maxWidth={maxWidth}>
        {children}
      </StyledModalContainer>
    </Modal>
  );
};
