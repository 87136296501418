import axios from 'axios';
import {signal} from './abortAPI';

const api = (apiKey?: string | null, contentType?: string) => {
  const headers: { [key: string]: string } = {
    'Content-Type': contentType ?? 'application/json',
  };
  if (apiKey) headers.Authorization = `Bearer ${apiKey}`;

  return axios.create({
    method: 'post',
    baseURL: `${
      window.location.hostname === 'localhost'
        ? 'https://env0-node5.comdinheiro.com.br'
        : 'https://www.comdinheiro.com.br'
    }/Clientes/`,
    headers,
    signal
  });
};

export default api;
