import React from 'react';
import { TextField } from '@mui/material';
import { useTheme } from 'styled-components';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/pt-br';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dates, DatesWrapper } from './style';
import { useAppSelector } from '../../../services/reduxHooks';

interface Props {
  dataInicial: string;
  dataFinal: string;
  setDataInicial: (value: string) => void;
  setDataFinal: (value: string) => void;
}

export default function CalendarioField({
  dataInicial,
  dataFinal,
  setDataInicial,
  setDataFinal,
}: Props) {
  const theme = useTheme();
  const dates = ['Data Inicial', 'Data Final'];
  const [carteiraList, loadedCarteiraList, carteira] = useAppSelector((state) => [
    state.preference.carteiraList,
    state.preference.loadedCarteiraList,
    state.preference.params.carteira,
  ]);

  let carteiraListAtual = carteiraList

  if (loadedCarteiraList && carteiraList && carteiraList[0] && carteira) {
    carteiraListAtual = carteiraList.filter((item) => item.nome_portfolio.toUpperCase() === carteira.toUpperCase())
  }

  console.log(carteira)

  const isDisabled = (date: Dayjs) => {
    const day = date.day();
    const dates = date.format('YYYY-MM-DD');
    const dataTemp = new Date(dates);
    let dataLimiteMin = new Date('1999-01-01');
    let dataLimiteMax = new Date('2099-12-31');
    if (carteiraListAtual && carteiraListAtual[0]) {
      dataLimiteMin = new Date(
        carteiraListAtual[0].data_mais_antiga_portfolio_AAAAmmDD,
      );
      dataLimiteMax = new Date(
        carteiraListAtual[0].data_mais_recente_portfolio_AAAAmmDD,
      );
    }
    return (
      day === 0 ||
      day === 6 ||
      carteiraListAtual[0].datas_nao_uteis.includes(dates) ||
      dataTemp > dataLimiteMax ||
      dataTemp < dataLimiteMin
    );
  };

  return (
    <DatesWrapper>
      {dates.map((item) => (
        <Dates key={item}>
          {item}
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
            <DatePicker
              value={dayjs(
                (item === 'Data Inicial' ? dataInicial : dataFinal).replace(
                  /(\d{2})(\d{2})(\d{4})/,
                  '$3-$2-$1',
                ),
              )}
              onChange={(newValue) => {
                if (item === 'Data Inicial') {
                  setDataInicial(newValue?.format('DDMMYYYY') ?? '');
                } else {
                  setDataFinal(newValue?.format('DDMMYYYY') ?? '');
                }
              }}
              dayOfWeekFormatter={(day) => {
                switch (day) {
                  case '2ª':
                  case '6ª':
                    day = 'S';
                    break;
                  case '4ª':
                  case '5ª':
                    day = 'Q';
                    break;
                  case '3ª':
                    day = 'T';
                    break;
                }
                return day.slice(0, 1);
              }}
              inputFormat="DD/MM/YYYY"
              label={'Selecione a data'}
              shouldDisableDate={isDisabled}
              renderInput={(params) => (
                <TextField
                  placeholder="dd/mm/yyyy"
                  size="small"
                  sx={{
                    marginTop: 2,
                    width: 180,
                    [`@media ${theme.device.mobileXL}`]: {
                      width: 140,
                    },
                    [`@media ${theme.device.mobileM}`]: {
                      width: 120,
                    },
                  }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Dates>
      ))}
    </DatesWrapper>
  );
}
