/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback } from 'react';
import { Box, Grid, Skeleton } from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  LabelList,
  Cell,
  Legend,
} from 'recharts';
import { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSquareFull } from '@fortawesome/free-solid-svg-icons';
import { helperFormat, FormatTypes } from '../../services/helper';
import useIsMobile from '../../services/useIsMobile';

interface IGraficoBarra {
  data: { name: string; value: number }[] | null;
  height?: number;
  onClickHandle?: (value: any) => void | null;
  valueType?: FormatTypes;
  decimals?: number;
  moneyPrefix?: string | null;
  colors: string | string[];
  hideXAxisLabels?: boolean;
  isPercentToggle?: boolean;
  handleOnAnimationEnd?: () => void;
}

export const GraficoBarra = ({
  data,
  valueType,
  height,
  decimals,
  moneyPrefix,
  onClickHandle,
  colors,
  hideXAxisLabels,
  isPercentToggle,
  handleOnAnimationEnd
}: IGraficoBarra) => {
  const theme = useTheme();
  const isMobile = useIsMobile();

  const useFormat = (value: any) =>
    helperFormat(
      value,
      valueType ?? (isPercentToggle ? FormatTypes.percentage : FormatTypes.monetary_extense),
      decimals ?? 2,
      moneyPrefix,
    );

  const handleClick = useCallback((entry: any) => {
    if (onClickHandle) onClickHandle(entry);
  }, []);

  const CustomLabel = ({
    x,
    y,
    value,
    width,
  }: {
    x?: number;
    y?: number;
    value?: number;
    width?: number;
  }) => {
    const isNegative = value! < 0;
    return (
      <text
        x={x! + width! / 2}
        y={y}
        dy={isNegative ? 16 : -5}
        fill={theme.fontColor}
        fontWeight="bold"
        textAnchor="middle">
        {useFormat(value)}
      </text>
    );
  };

  const renderLegend = ({ payload }: any) => {
    if (payload.length % 2 !== 0) {
      payload.push({ id: '', value: '' });
    }
    return (
      <Grid
        container
        style={{ marginTop: 16, fontSize: 12, height: 150, overflowY: 'auto' }}
        justifyContent="space-evenly">
        {payload.map((entry: any, index: any) => (
          <React.Fragment key={index}>
            {gridItem({
              index,
              id: entry.id,
              value: entry.value,
              color: theme.chartColors[index % theme.chartColors.length],
            })}
          </React.Fragment>
        ))}
      </Grid>
    );
  };

  const gridItem = ({
    index,
    id,
    value,
    color,
  }: {
    index: number;
    id: string | undefined;
    value: string | number;
    color: string;
  }) => {
    return (
      <Grid key={`item-${index}`} item xs={12} md={6} sx={{ whiteSpace: 'nowrap' }}>
        {id ? (
          <>
            <Grid container style={{ color: '#596579', fontWeight: 500, padding: '10px' }}>
              <Grid
                item
                xs={10}
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}>
                <FontAwesomeIcon
                  icon={faSquareFull as IconProp}
                  style={{ color, marginRight: 4 }}
                />{' '}
                <span>{id}</span>
              </Grid>
              <Grid item xs={2}>
                <Box display="flex" justifyContent="flex-end">
                  <b>{useFormat(value)}</b>
                </Box>
              </Grid>
            </Grid>
          </>
        ) : null}
      </Grid>
    );
  };

  return data && data.length > 0 ? (
    <div style={{ marginTop: 50 }}>
      <ResponsiveContainer width="100%" height={height}>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 16,
            right: 32,
            left: 24,
            bottom: 32,
          }}
          onClick={(data) => {
            if (data && data.activePayload && data.activePayload.length > 0) {
              handleClick(data.activePayload[0]);
            }
          }}>
          <CartesianGrid vertical={false} strokeDasharray="0" />
          <XAxis
            tickLine={false}
            axisLine={false}
            dataKey="name"
            tick={hideXAxisLabels ? false : true}
            angle={45}
            dx={15}
            dy={20}
            minTickGap={-200}
          />
          {hideXAxisLabels ? (
            <Legend
              payload={data.map((item, index) => ({
                id: item.name,
                type: 'square',
                value: item.value,
                color: theme.chartColors[index % theme.chartColors.length],
              }))}
              content={renderLegend}
              verticalAlign="bottom"
              style={{ height: '300px !important' }}
            />
          ) : null}
          <YAxis
            tickLine={false}
            axisLine={false}
            tick={{ width: 80 }}
            padding={{ bottom: 24 }}
            domain={['auto', Math.max(...data.map((o) => o.value)) * 1.1]}
            tickFormatter={useFormat}
          />
          <Tooltip
            wrapperStyle={{ outline: 'none' }}
            formatter={(value) => [useFormat(value), 'Valor']}
          />
          <ReferenceLine y={0} stroke={theme.fontColor} />
          <Bar dataKey="value" onAnimationEnd ={handleOnAnimationEnd}>
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                cursor="pointer"
                fill={typeof colors !== 'string' ? colors[index % colors.length] : colors}
              />
            ))}
            {isMobile ? null : (
              <LabelList dataKey="value" position="top" content={<CustomLabel />} />
            )}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  ) : (
    <Skeleton variant="rectangular" height={height} width="100%" />
  );
};
