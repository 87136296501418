import { checkPeriodoPersonalizado } from './../helper';
import { AxiosError } from 'axios';
import { IFluxoCaixaState } from './../reducers/fluxoCaixaSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import mockFluxoCaixa from '../mock/fluxoCaixa.json';
import api from './index';

interface IFluxoCaixaAPI {
  FluxoCaixa: IFluxoCaixaState;
  access_token?: string;
  expires_in?: number;
  msg?: string;
  codigo?: string;
}

export const fetchFluxoCaixa = createAsyncThunk<IFluxoCaixaAPI, void, { state: RootState }>(
  'fluxoCaixa/fetch',
  async (nothing: void, thunkAPI) => {
    if (process.env.REACT_APP_MOCK_API === 'true') return mockFluxoCaixa;
    try {
      const perdiodoPersonalizado = checkPeriodoPersonalizado(
        thunkAPI.getState().preference?.params.periodo,
      );

      const periodo =
        thunkAPI.getState().preference.params.periodo === 'maximo'
          ? 'data_ini_acomp'
          : thunkAPI.getState().preference.params.periodo;

      const data = JSON.stringify({
        nome_portfolio: thunkAPI.getState().preference?.params.carteira ?? '',

        data_fim: perdiodoPersonalizado ? thunkAPI.getState().preference?.dateEnd : '31/12/9999',
        data_ini: perdiodoPersonalizado ? thunkAPI.getState().preference?.dateStart : periodo,
        // classe: 'MV(estrategia) TIPO',
        // subclasse: 'MV(risco)',
        abas: ['FluxoCaixa'],
        IFCaixa: thunkAPI.getState().fluxoCaixa?.IF,
        recalcular: thunkAPI.getState().preference?.recalcular ? 1 : 0,
      });

      const token = thunkAPI.getState().preference?.token.value ?? '';
      const response = await api(token).post(
        `API/EndPoint002.php?code=${process.env.REACT_APP_APICODE}&service=CarregaCoreExtratoWhitelabel001`,
        data,
      );

      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue(err.response?.data);
    }
  },
);

interface IListaIFsAPI {
  IFsCaixa: {
    obj_datas: {
      data_ini: string;
      data_fim: string;
      data_ini2: string;
    };
    IFsCaixa: string[];
  };
  access_token?: string;
  expires_in?: number;
  msg?: string;
  codigo?: string;
}

export const fetchIFsCaixa = createAsyncThunk<IListaIFsAPI, void, { state: RootState }>(
  'fluxoCaixa/fetchListaIFs',
  async (nothing: void, thunkAPI) => {
    if (process.env.REACT_APP_MOCK_API === 'true') return mockFluxoCaixa;
    try {
      const perdiodoPersonalizado = checkPeriodoPersonalizado(
        thunkAPI.getState().preference?.params.periodo,
      );

      const periodo =
        thunkAPI.getState().preference.params.periodo === 'maximo'
          ? 'data_ini_acomp'
          : thunkAPI.getState().preference.params.periodo;

      const data = JSON.stringify({
        nome_portfolio: thunkAPI.getState().preference?.params.carteira ?? '',

        data_fim: perdiodoPersonalizado ? thunkAPI.getState().preference?.dateEnd : '31/12/9999',
        data_ini: perdiodoPersonalizado ? thunkAPI.getState().preference?.dateStart : periodo,
        classe: 'MV(estrategia) TIPO',
        subclasse: 'MV(risco)',
        abas: ['IFsCaixa'],
        recalcular: thunkAPI.getState().preference?.recalcular ? 1 : 0,
      });

      const token = thunkAPI.getState().preference?.token.value ?? '';
      const response = await api(token).post(
        `API/EndPoint002.php?code=${process.env.REACT_APP_APICODE}&service=CarregaCoreExtratoWhitelabel001`,
        data,
      );

      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue(err.response?.data);
    }
  },
);
