import React from 'react';
import { Box, TableHead, TableRow, TableSortLabel } from '@mui/material/';
import { visuallyHidden } from '@mui/utils';
import {
  StyledCell,
} from './style';
import { useTheme } from 'styled-components';
import { IData, Order } from '.';

export enum AlignTypes {
  center = 'center',
  left = 'left',
  right = 'right',
}
export interface ITableColumns {
  id: string | number;
  orderBy?: string | number;
  label: string;
  align?: AlignTypes;
  headerAlign?: AlignTypes;
  colorLegend?: boolean;
  color?: string;
  bgColor?: string;
  rowSpan?: number;
  padding?: string;
  borderLeftRadius?: string;
  bold?: boolean;
  freeze?: boolean;
  width?: string;
  leftOnFreeze?: number;
  noBreak?: boolean;
}

interface IEnhancedTableHeadProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IData) => void;
  order: Order;
  orderBy: string | number;
  columns: ITableColumns[];
  fontSize?: number;
}

export default function EnhancedTableHead({
  columns,
  onRequestSort,
  order,
  orderBy,
  fontSize,
}: IEnhancedTableHeadProps) {
  const theme = useTheme();

  const createSortHandler = (property: keyof IData) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column, i) => {
          const align = column.headerAlign ?? column.align;
          return (
            <StyledCell
              key={column.id}
              $borderRight={column.freeze || i === 0}
              $freeze={column.freeze ?? false}
              $leftOnFreeze={column.leftOnFreeze ?? 0}
              $zIndex={column.freeze ? 4 : 3}
              $paddingLeft={
                column.freeze && typeof column.colorLegend !== 'undefined' ? true : false
              }
              align={align ?? 'left'}
              sortDirection={orderBy === column.id ? order : false}
              sx={{
                padding: column.padding ?? '8px',
                marginLeft: column.colorLegend ? '16px' : '',
                borderBottom: column.bgColor ? '0px' : '2px solid #BEBEBE',
                backgroundColor: column.bgColor ? theme.backgroundColor : 'white',
                color: theme.titleFontColor,
                fontWeight: 'bold',
                fontSize: fontSize ?? 14,
              }}>
              {column?.orderBy ? (
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={orderBy === column.id ? order : 'asc'}
                  onClick={createSortHandler(column.id)}>
                  {column.label}
                  {orderBy === column.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                column.label
              )}
            </StyledCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
