import { fetchEstats } from './../api/estatsAPI';
import { fetchFluxoCaixa, fetchIFsCaixa } from './../api/fluxoCaixaAPI';
import { changePassword } from './../api/preferenceAPI';
import { fetchResumoCart, fetchResumoCartPerf } from './../api/resumoCartAPI';
import { fetchPerfHist, fetchPerfHistPerf } from './../api/perfHistAPI';
import { fetchModalPosCon, fetchPosCon } from './../api/posConAPI';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchCarteiraList, fetchPreference } from '../api/preferenceAPI';
import { RootState } from '../store';
import { checkPeriodoPersonalizado } from '../helper';
import { fetchFluxoAtivos } from '../api/fluxoAtivosAPI';

export interface ICarteira {
  nome_portfolio: string;
  alias_nome_portfolio: string;
  data_mais_antiga_considerada: string;
  data_mais_recente_portfolio_AAAAmmDD: string;
  pl_data_mais_recente: number;
  data_mais_antiga_portfolio_AAAAmmDD: string;
  calendario: string;
  datas_nao_uteis: string[];
  omitir_retornos: string;
  data_ini_acomp_AAAAmmDD: string;
  moeda: string;
  CV_bench_opcao: {
    label: string;
    value: string;
  }[];
  CV_bench_carteira1: string[];
  CV_bench_mes_a_mes: string[];
  CV_bench_liberado: string[];
}

export interface IToken {
  value: string | null;
  expiration: number | null;
}

export interface IParams {
  carteira: string | null;
  periodo: string;
  [key: string]: string | number | null;
}

export interface IPreferenceState {
  loading: boolean;
  loadingCarteiraList: boolean;
  loadedCarteiraList: boolean;
  loadingTrocarSenha: boolean;
  trocarSenhaStatus: 'ok' | 'error' | 'none';
  loginError: boolean;
  recalcular: boolean;
  token: IToken;
  usuario: string;
  id: number | null;
  carteiraList: ICarteira[];
  flagTrocaSenha: string | never[];
  dateStart: string;
  dateEnd: string;
  params: IParams;
  loggedIn: boolean;
}

const initialState: IPreferenceState = {
  loading: false,
  loadingCarteiraList: false,
  loadedCarteiraList: false,
  loadingTrocarSenha: false,
  trocarSenhaStatus: 'none',
  loginError: false,
  recalcular: false,
  token: {
    value: null,
    expiration: null,
  },
  usuario: '',
  id: null,
  carteiraList: [],
  flagTrocaSenha: '',
  dateStart: '',
  dateEnd: '',
  params: {
    carteira: null,
    periodo: '',
  },
  loggedIn: false,
};

export const preferenceSlice = createSlice({
  name: 'preference',
  initialState,
  reducers: {
    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload;
    },
    setToken: (state, action: PayloadAction<{ token: string; expiration: number | null }>) => {
      state.token.value = action.payload.token ?? null;
      state.token.expiration = action.payload.expiration ?? null;
    },
    setStatusSenha: (state, action: PayloadAction<'ok' | 'error' | 'none'>) => {
      state.trocarSenhaStatus = action.payload;
    },
    resetParams: () => {
      return initialState;
    },
    setCarteiraID: (state, action: PayloadAction<string | null>) => {
      state.params.carteira = action.payload;
    },
    toggleRecalcular: (state) => {
      state.recalcular = !state.recalcular;
    },
    resetStateHelper: (state) => {
      return state;
    },
    setParams: (state, action: PayloadAction<IParams>) => {
      for (const prop in state.params) {
        if (
          Object.hasOwnProperty.call(state.params, prop) &&
          Object.hasOwnProperty.call(action.payload, prop) &&
          action.payload[prop]
        ) {
          state.params[prop] = action.payload[prop];
        }
      }

      const matches = checkPeriodoPersonalizado(state.params.periodo ?? '');
      if (matches) {
        state.dateStart = matches.start;
        state.dateEnd = matches.end;
      }
    },
    setDataPersonalizada: (state, action) => {
      state.dateStart = action.payload.dataIni;
      state.dateEnd = action.payload.dataFim;
      state.params.periodo = `${action.payload.dataIni}+${action.payload.dataFim}`;
    },
    setData: (state, action) => {
      state.params.periodo = action.payload;
    },
  },

  extraReducers: (builder) => {
    // change password cases
    builder.addCase(changePassword.fulfilled, (state) => {
      state.loadingTrocarSenha = false;
      state.trocarSenhaStatus = 'ok';
    });
    builder.addCase(changePassword.pending, (state) => {
      state.loadingTrocarSenha = true;
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      state.loadingTrocarSenha = false;
      const payload = action.payload as { errors: { msg: string; codigo: string }[] };
      if (payload.errors[0] && payload.errors[0].codigo === '1b6321') {
        state.trocarSenhaStatus = 'error';
      }
    });

    // login cases
    builder.addCase(fetchPreference.pending, (state) => {
      state.loading = true;
      state.loginError = false;
    });
    builder.addCase(fetchPreference.fulfilled, (state, action) => {
      state.loading = false;
      state.flagTrocaSenha = action.payload.usuario_final?.recadastrar_senha_proximo_acesso ?? [];
      if (action.payload.access_token) {
        state.token.value = action.payload.access_token;
        state.token.expiration = action.payload.expires_in
          ? new Date(action.payload?.expires_in).getTime()
          : null;
        window.localStorage.setItem(
          `token-${process.env.REACT_APP_NAME ?? 'comdinheiro'}`,
          JSON.stringify({ ...state.token }),
        );
      }
    });
    builder.addCase(fetchPreference.rejected, (state) => {
      state.loading = false;
      state.loginError = true;
    });

    // list portfolio cases
    builder.addCase(fetchCarteiraList.pending, (state) => {
      state.loadingCarteiraList = true;
    });
    builder.addCase(fetchCarteiraList.fulfilled, (state, action) => {
      state.loadedCarteiraList = true;
      state.loadingCarteiraList = false;
      state.carteiraList = action.payload.ListaCarteiras ?? [];

      if (action.payload.access_token && action.payload.expires_in) {
        state.token.value = action.payload.access_token;
        state.token.expiration = action.payload.expires_in * 1000;

        window.localStorage.setItem(
          `token-${process.env.REACT_APP_NAME ?? 'comdinheiro'}`,
          JSON.stringify({ ...state.token }),
        );
      }
    });

    // expired token cases for each API call
    builder.addCase(fetchResumoCart.rejected, (state, action) => {
      const payload = action.payload as { msg: string; code: string };
      if (payload && payload.msg === 'Expired token') {
        window.localStorage.removeItem(`token-${process.env.REACT_APP_NAME ?? 'comdinheiro'}`);
        state.token.value = '';
      }
    });
    builder.addCase(fetchResumoCartPerf.rejected, (state, action) => {
      const payload = action.payload as { msg: string; code: string };
      if (payload && payload.msg === 'Expired token') {
        window.localStorage.removeItem(`token-${process.env.REACT_APP_NAME ?? 'comdinheiro'}`);
        state.token.value = '';
      }
    });
    builder.addCase(fetchCarteiraList.rejected, (state, action) => {
      const payload = action.payload as { msg: string; code: string };
      if (payload && payload.msg === 'Expired token') {
        window.localStorage.removeItem(`token-${process.env.REACT_APP_NAME ?? 'comdinheiro'}`);
        state.token.value = '';
      }
    });
    builder.addCase(fetchPerfHist.rejected, (state, action) => {
      const payload = action.payload as { msg: string; code: string };
      if (payload && payload.msg === 'Expired token') {
        window.localStorage.removeItem(`token-${process.env.REACT_APP_NAME ?? 'comdinheiro'}`);
        state.token.value = '';
      }
    });
    builder.addCase(fetchPerfHistPerf.rejected, (state, action) => {
      const payload = action.payload as { msg: string; code: string };
      if (payload && payload.msg === 'Expired token') {
        window.localStorage.removeItem(`token-${process.env.REACT_APP_NAME ?? 'comdinheiro'}`);
        state.token.value = '';
      }
    });
    builder.addCase(fetchPosCon.rejected, (state, action) => {
      const payload = action.payload as { msg: string; code: string };
      if (payload && payload.msg === 'Expired token') {
        window.localStorage.removeItem(`token-${process.env.REACT_APP_NAME ?? 'comdinheiro'}`);
        state.token.value = '';
      }
    });
    builder.addCase(fetchModalPosCon.rejected, (state, action) => {
      const payload = action.payload as { msg: string; code: string };
      if (payload && payload.msg === 'Expired token') {
        window.localStorage.removeItem(`token-${process.env.REACT_APP_NAME ?? 'comdinheiro'}`);
        state.token.value = '';
      }
    });
    builder.addCase(fetchFluxoAtivos.rejected, (state, action) => {
      const payload = action.payload as { msg: string; code: string };
      if (payload && payload.msg === 'Expired token') {
        window.localStorage.removeItem(`token-${process.env.REACT_APP_NAME ?? 'comdinheiro'}`);
        state.token.value = '';
      }
    });
    builder.addCase(fetchFluxoCaixa.rejected, (state, action) => {
      const payload = action.payload as { msg: string; code: string };
      if (payload && payload.msg === 'Expired token') {
        window.localStorage.removeItem(`token-${process.env.REACT_APP_NAME ?? 'comdinheiro'}`);
        state.token.value = '';
      }
    });
    builder.addCase(fetchIFsCaixa.rejected, (state, action) => {
      const payload = action.payload as { msg: string; code: string };
      if (payload && payload.msg === 'Expired token') {
        window.localStorage.removeItem(`token-${process.env.REACT_APP_NAME ?? 'comdinheiro'}`);
        state.token.value = '';
      }
    });
    builder.addCase(fetchEstats.rejected, (state, action) => {
      const payload = action.payload as { msg: string; code: string };
      if (payload && payload.msg === 'Expired token') {
        window.localStorage.removeItem(`token-${process.env.REACT_APP_NAME ?? 'comdinheiro'}`);
        state.token.value = '';
      }
    });

    // set new token and date cases for each API call
    builder.addCase(fetchResumoCart.fulfilled, (state, action) => {
      state.recalcular = false;
      if (
        action.payload.ResumoCart?.obj_datas &&
        action.payload.ResumoCart?.obj_datas.data_fim !== state.dateEnd
      ) {
        state.dateEnd = action.payload.ResumoCart.obj_datas.data_fim.split('/').join('');
      }
      if (
        action.payload.ResumoCart?.obj_datas &&
        action.payload.ResumoCart?.obj_datas.data_ini !== state.dateStart
      ) {
        state.dateStart = action.payload.ResumoCart.obj_datas.data_ini.split('/').join('');
      }

      if (action.payload.access_token && action.payload.expires_in) {
        state.token.value = action.payload.access_token;
        state.token.expiration = action.payload.expires_in * 1000;

        window.localStorage.setItem(
          `token-${process.env.REACT_APP_NAME ?? 'comdinheiro'}`,
          JSON.stringify({ ...state.token }),
        );
      }
    });
    builder.addCase(fetchResumoCartPerf.fulfilled, (state, action) => {
      if (action.payload.access_token && action.payload.expires_in) {
        state.token.value = action.payload.access_token;
        state.token.expiration = action.payload.expires_in * 1000;

        window.localStorage.setItem(
          `token-${process.env.REACT_APP_NAME ?? 'comdinheiro'}`,
          JSON.stringify({ ...state.token }),
        );
      }
    });
    builder.addCase(fetchPerfHist.fulfilled, (state, action) => {
      state.recalcular = false;
      if (
        action.payload.PerfHist.obj_datas &&
        action.payload.PerfHist.obj_datas.data_fim !== state.dateEnd
      ) {
        state.dateEnd = action.payload.PerfHist.obj_datas.data_fim.split('/').join('');
      }
      if (
        action.payload.PerfHist.obj_datas &&
        action.payload.PerfHist.obj_datas.data_ini !== state.dateStart
      ) {
        state.dateStart = action.payload.PerfHist.obj_datas.data_ini.split('/').join('');
      }

      if (action.payload.access_token && action.payload.expires_in) {
        state.token.value = action.payload.access_token;
        state.token.expiration = action.payload.expires_in * 1000;

        window.localStorage.setItem(
          `token-${process.env.REACT_APP_NAME ?? 'comdinheiro'}`,
          JSON.stringify({ ...state.token }),
        );
      }
    });
    builder.addCase(fetchPerfHistPerf.fulfilled, (state, action) => {
      if (action.payload.access_token && action.payload.expires_in) {
        state.token.value = action.payload.access_token;
        state.token.expiration = action.payload.expires_in * 1000;

        window.localStorage.setItem(
          `token-${process.env.REACT_APP_NAME ?? 'comdinheiro'}`,
          JSON.stringify({ ...state.token }),
        );
      }
    });
    builder.addCase(fetchPosCon.fulfilled, (state, action) => {
      state.recalcular = false;
      if (
        action.payload.PosCon.obj_datas &&
        action.payload.PosCon.obj_datas.data_fim !== state.dateEnd
      ) {
        state.dateEnd = action.payload.PosCon.obj_datas.data_fim.split('/').join('');
      }
      if (
        action.payload.PosCon.obj_datas &&
        action.payload.PosCon.obj_datas.data_ini !== state.dateStart
      ) {
        state.dateStart = action.payload.PosCon.obj_datas.data_ini.split('/').join('');
      }

      if (action.payload.access_token && action.payload.expires_in) {
        state.token.value = action.payload.access_token;
        state.token.expiration = action.payload.expires_in * 1000;

        window.localStorage.setItem(
          `token-${process.env.REACT_APP_NAME ?? 'comdinheiro'}`,
          JSON.stringify({ ...state.token }),
        );
      }
    });
    builder.addCase(fetchModalPosCon.fulfilled, (state, action) => {
      if (action.payload.access_token && action.payload.expires_in) {
        state.token.value = action.payload.access_token;
        state.token.expiration = action.payload.expires_in * 1000;

        window.localStorage.setItem(
          `token-${process.env.REACT_APP_NAME ?? 'comdinheiro'}`,
          JSON.stringify({ ...state.token }),
        );
      }
    });
    builder.addCase(fetchFluxoAtivos.fulfilled, (state, action) => {
      state.recalcular = false;
      if (
        action.payload.FluxoAtivos.obj_datas &&
        action.payload.FluxoAtivos.obj_datas.data_fim !== state.dateEnd
      ) {
        state.dateEnd = action.payload.FluxoAtivos.obj_datas.data_fim.split('/').join('');
      }
      if (
        action.payload.FluxoAtivos.obj_datas &&
        action.payload.FluxoAtivos.obj_datas.data_ini !== state.dateStart
      ) {
        state.dateStart = action.payload.FluxoAtivos.obj_datas.data_ini.split('/').join('');
      }

      if (action.payload.access_token && action.payload.expires_in) {
        state.token.value = action.payload.access_token;
        state.token.expiration = action.payload.expires_in * 1000;

        window.localStorage.setItem(
          `token-${process.env.REACT_APP_NAME ?? 'comdinheiro'}`,
          JSON.stringify({ ...state.token }),
        );
      }
    });
    builder.addCase(fetchFluxoCaixa.fulfilled, (state, action) => {
      state.recalcular = false;
      if (
        action.payload.FluxoCaixa.obj_datas &&
        action.payload.FluxoCaixa.obj_datas.data_fim !== state.dateEnd
      ) {
        state.dateEnd = action.payload.FluxoCaixa.obj_datas.data_fim.split('/').join('');
      }
      if (
        action.payload.FluxoCaixa.obj_datas &&
        action.payload.FluxoCaixa.obj_datas.data_ini !== state.dateStart
      ) {
        state.dateStart = action.payload.FluxoCaixa.obj_datas.data_ini.split('/').join('');
      }

      if (action.payload.access_token && action.payload.expires_in) {
        state.token.value = action.payload.access_token;
        state.token.expiration = action.payload.expires_in * 1000;

        window.localStorage.setItem(
          `token-${process.env.REACT_APP_NAME ?? 'comdinheiro'}`,
          JSON.stringify({ ...state.token }),
        );
      }
    });
    builder.addCase(fetchIFsCaixa.fulfilled, (state, action) => {
      state.recalcular = false;
      if (
        action.payload.IFsCaixa.obj_datas &&
        action.payload.IFsCaixa.obj_datas.data_fim !== state.dateEnd
      ) {
        state.dateEnd = action.payload.IFsCaixa.obj_datas.data_fim.split('/').join('');
      }
      if (
        action.payload.IFsCaixa.obj_datas &&
        action.payload.IFsCaixa.obj_datas.data_ini !== state.dateStart
      ) {
        state.dateStart = action.payload.IFsCaixa.obj_datas.data_ini.split('/').join('');
      }

      if (action.payload.access_token && action.payload.expires_in) {
        state.token.value = action.payload.access_token;
        state.token.expiration = action.payload.expires_in * 1000;

        window.localStorage.setItem(
          `token-${process.env.REACT_APP_NAME ?? 'comdinheiro'}`,
          JSON.stringify({ ...state.token }),
        );
      }
    });
    builder.addCase(fetchEstats.fulfilled, (state, action) => {
      state.recalcular = false;
      if (
        action.payload.EstatAvanc.obj_datas &&
        action.payload.EstatAvanc.obj_datas.data_fim !== state.dateEnd
      ) {
        state.dateEnd = action.payload.EstatAvanc.obj_datas.data_fim.split('/').join('');
      }
      if (
        action.payload.EstatAvanc.obj_datas &&
        action.payload.EstatAvanc.obj_datas.data_ini !== state.dateStart
      ) {
        state.dateStart = action.payload.EstatAvanc.obj_datas.data_ini.split('/').join('');
      }

      if (action.payload.access_token && action.payload.expires_in) {
        state.token.value = action.payload.access_token;
        state.token.expiration = action.payload.expires_in * 1000;

        window.localStorage.setItem(
          `token-${process.env.REACT_APP_NAME ?? 'comdinheiro'}`,
          JSON.stringify({ ...state.token }),
        );
      }
    });
  },
});

export const {
  setLoggedIn,
  setToken,
  setStatusSenha,
  resetParams,
  setDataPersonalizada,
  setData,
  setParams,
  setCarteiraID,
  toggleRecalcular,
  resetStateHelper,
} = preferenceSlice.actions;

export const selectToken = (state: RootState) => state.preference.token;

export default preferenceSlice.reducer;
