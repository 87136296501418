import React, { useEffect } from 'react';
import Header from '../../../components/Header';
import { Line } from '../../../components/Line';
import { useAppSelector, useAppDispatch } from '../../../services/reduxHooks';
import EnhancedTable from '../../../components/Table';
import { AlignTypes, ITableColumns } from '../../../components/Table/TableHeader';
import { Subtitulo } from '../../../components/Titulo';
import { sendAnalyticsData } from '../../../services/api/preferenceAPI';
import { fetchEstats } from '../../../services/api/estatsAPI';
import { Grid } from '@mui/material';
import LoadingModal from '../LoadingModal';
import FixedLoadingButton from '../../../components/FixedLoadingButton';
import { FormatTypes, helperFormat, limitToModulus } from '../../../services/helper';
import { fetchFluxoAtivos } from '../../../services/api/fluxoAtivosAPI';
import { fetchPosCon } from '../../../services/api/posConAPI';
// import { fetchCartExp } from '../../../services/api/cartExpAPI';
import { fetchResumoCart } from '../../../services/api/resumoCartAPI';
import { fetchIFsCaixa } from '../../../services/api/fluxoCaixaAPI';
import { fetchPerfHist } from '../../../services/api/perfHistAPI';
import MoneyPrefix from '../../../components/MoneyPrefix';

interface Props {
  dispatchPDF?: boolean
}

export default function PerformanceHistorica({dispatchPDF}: Props) {
  const dispatch = useAppDispatch();
  const [
    infos_add,
    retorno_carteira_bench,
    vol_carteira_bench,
    sharpe_carteira_bench,
    obj_datas,
    isLoaded,
    loading,
    carteiraList,
    isLoadingMinimized,
    params,
  ] = useAppSelector((state) => [
    state.estats.infos_add,
    state.estats.retorno_carteira_bench,
    state.estats.vol_carteira_bench,
    state.estats.sharpe_carteira_bench,
    state.estats.obj_datas,
    state.estats.isLoaded,
    state.estats.loading,
    state.preference.carteiraList,
    state.loadMinimized.isLoadingMinimized,
    state.preference.params,
  ]);

  useEffect(() => {
    if(!dispatchPDF) document.title = `${process.env.REACT_APP_TABNAME} - Estatísticas`;
  }, []);
  useEffect(() => {
    if (isLoaded && !dispatchPDF) dispatch(sendAnalyticsData(location.href));
  }, [params, isLoaded]);

  useEffect(() => {
    async function loadDados() {
      await dispatch(fetchEstats());
    }
    if ((!isLoaded && !loading) && !dispatchPDF) {
      loadDados();

      dispatch(fetchResumoCart());
      dispatch(fetchPerfHist());
      dispatch(fetchPosCon());
      // dispatch(fetchCartExp());
      dispatch(fetchFluxoAtivos('mes_atual'));
      dispatch(fetchIFsCaixa());
    }
  }, [isLoaded]);

  const moneyPrefix = MoneyPrefix(carteiraList, params.carteira);

  interface IRowsInfo1 {
    info: string | number;
    carteira?: string | number;
    bench?: string | number;
    colSpan?: number;
  }

  const rowsInfos1: IRowsInfo1[] | null =
    isLoaded && infos_add && infos_add.retorno && infos_add.pl_data_fim && infos_add.pl_medio
      ? infos_add.retorno.slice(1).map((row) => {
          let i = 0;
          return {
            info: row[i++],
            carteira: helperFormat(row[i++], FormatTypes.decimals, 2),
            bench: helperFormat(row[i++], FormatTypes.decimals, 2),
          };
        })
      : null;
  if (rowsInfos1 !== null && rowsInfos1.length > 0) {
    rowsInfos1.push({
      info: `PL em ${obj_datas.data_fim}`,
      carteira: '',
      bench: helperFormat(infos_add.pl_data_fim, FormatTypes.monetary, 2, moneyPrefix),
    });
    rowsInfos1.push({
      info: 'PL médio no período',
      carteira: '',
      bench: helperFormat(infos_add.pl_medio, FormatTypes.monetary, 2, moneyPrefix),
    });
  }

  const columnsInfos1: ITableColumns[] = [
    {
      id: 'info',
      label: '',
      align: AlignTypes.left,
    },
    {
      id: 'carteira',
      label: 'Carteira',
      align: AlignTypes.right,
    },
    {
      id: 'bench',
      label: isLoaded && infos_add && infos_add.retorno ? infos_add.retorno[0][2].toString() : '-',
      align: AlignTypes.right,
    },
  ];

  const rowsInfos2 =
    isLoaded && infos_add && infos_add.comparativo
      ? infos_add.comparativo.slice(1).map((row) => {
          let i = 0;
          return {
            info: row[i++],
            valor: row[i++],
            percent: helperFormat(row[i++], FormatTypes.percentage, 2),
          };
        })
      : null;

  const columnsInfos2: ITableColumns[] = [
    {
      id: 'info',
      label: '',
      align: AlignTypes.left,
    },
    {
      id: 'valor',
      label: 'Valor',
      align: AlignTypes.right,
    },
    {
      id: 'percent',
      label: '%',
      align: AlignTypes.right,
    },
  ];

  const rowsRetornos =
    isLoaded && retorno_carteira_bench
      ? retorno_carteira_bench.slice(1).map((row) => {
          let i = 0;
          const returnObj: { [key: string]: number | string } = {
            periodo: row[i++],
            carteira: helperFormat(row[i++], FormatTypes.decimals, 2),
          };

          row.slice(2).forEach((val, i) => {
            returnObj[`bench${i}`] = helperFormat(val, FormatTypes.decimals, 2);
          });
          return returnObj;
        })
      : null;

  const rowsVol =
    isLoaded && vol_carteira_bench
      ? vol_carteira_bench.slice(1).map((row) => {
          let i = 0;
          const returnObj: { [key: string]: number | string } = {
            periodo: row[i++],
            carteira: helperFormat(row[i++], FormatTypes.decimals, 2),
          };

          row.slice(2).forEach((val, i) => {
            returnObj[`bench${i}`] = helperFormat(val, FormatTypes.decimals, 2);
          });
          return returnObj;
        })
      : null;

  const rowsSharpe =
    isLoaded && sharpe_carteira_bench
      ? sharpe_carteira_bench.slice(1).map((row) => {
          let i = 0;
          const returnObj: { [key: string]: number | string } = {
            periodo: row[i++],
            carteira: helperFormat(row[i++], FormatTypes.decimals, 2),
          };

          row.slice(2).forEach((val, i) => {
            returnObj[`bench${i}`] = helperFormat(limitToModulus(val, 999.99), FormatTypes.decimals, 2);
          });
          return returnObj;
        })
      : null;

  const columnsDefault: ITableColumns[] = [
    {
      id: 'periodo',
      label: 'Período',
      freeze: true,
      align: AlignTypes.left,
    },
    {
      id: 'carteira',
      label: 'Carteira',
      align: AlignTypes.right,
    },
  ];

  if (isLoaded && retorno_carteira_bench && retorno_carteira_bench[0]) {
    retorno_carteira_bench[0].slice(2).forEach((val, i) => {
      columnsDefault.push({
        id: `bench${i}`,
        label: val.toString(),
        align: AlignTypes.right,
      });
    });
  }

  return (
    <>
      <Grid>
      <Header title="Estatísticas Avançadas" periodo obj_datas={obj_datas} />
      <Line />
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Subtitulo mb="8px">Informações Adicionais</Subtitulo>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <EnhancedTable rows={rowsInfos1} columns={columnsInfos1} height={500} />
            </Grid>
            <Grid item xs={12} md={6}>
              <EnhancedTable rows={rowsInfos2} columns={columnsInfos2} height={500} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Subtitulo mb="8px">Retorno</Subtitulo>
          <EnhancedTable rows={rowsRetornos} columns={columnsDefault} height={500} />
        </Grid>
        <Grid item xs={12}>
          <Subtitulo mb="8px">Volatilidade</Subtitulo>
          <EnhancedTable rows={rowsVol} columns={columnsDefault} height={500} />
        </Grid>
        <Grid item xs={12}>
          <Subtitulo mb="8px">Índice Sharpe</Subtitulo>
          <EnhancedTable rows={rowsSharpe} columns={columnsDefault} height={500} />
        </Grid>
      </Grid>
      <LoadingModal loading={(!isLoaded && !isLoadingMinimized) ?? false} />
      <FixedLoadingButton loading={!isLoaded ?? false} isLoadingMinimized={isLoadingMinimized}/>
    </>
  );
}
