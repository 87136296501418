import React from 'react';
import MainComponent from './pages/MainComponent';
import { Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';
import LoginPage from './pages/Login';
import EsqueciMinhaSenha from './pages/Login/EsqueciMinhaSenha';

import useLoggedIn from './services/useLoggedIn';
import useParameters from './services/useParameters';
import AgendamentoPDF from './pages/MainComponent/AgendamentoPDF';

function App() {
  const loggedIn = useLoggedIn();
  useParameters();
  return (
    <Routes>
      <Route
        key="login"
        path="/login"
        element={
          <WithTheme mode="light">
            <LoginPage />
          </WithTheme>
        }
      />
      <Route
        key="EsqueciMinhaSenha"
        path="/EsqueciMinhaSenha"
        element={
          <WithTheme mode="dark">
            <EsqueciMinhaSenha />
          </WithTheme>
        }
      />
      <Route
        key="main"
        path="/*"
        element={<WithTheme mode="light">{loggedIn ? <MainComponent /> : null}</WithTheme>}
      />
      <Route
        key="agendamentoPDF"
        path="/AgendamentoPDF"
        element={
          <WithTheme mode="dark">
            <AgendamentoPDF />
          </WithTheme>
        }
      />
    </Routes>
  );
}

export default App;

function WithTheme({
  mode,
  children,
}: {
  mode: 'light' | 'dark';
  children: JSX.Element | null;
}): React.ReactElement {
  const theme = createTheme({
    palette: {
      mode,
    },
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
